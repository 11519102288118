import { FC, useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import classNames from "classnames";
import _, { kebabCase } from "lodash";
import moment from "moment";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import useAccessType from "@hooks/use-access-type";
import { Appointment } from "@hooks/use-appointments";
import { useListConfiguration } from "@hooks/use-list-configuration";
import useMemberFilterOptions from "@hooks/use-member-filter-options";
import usePaginatedAppointments from "@hooks/use-paginated-appointments";
import { useIsMobile } from "@hooks/use-window-size";
import analytics from "@lib/analytics";
import { FormattedPaginatedAppointment } from "@lib/utils/appointments/formatAppointment";
import { Appointment as GroupAppointment } from "@lib/utils/appointments/groupAppointments";
import { getArtifactLink } from "@lib/utils/artifacts";

import CalendarIcon from "./Icons/CalendarIcon";
import CalendarArrowIcon from "./Icons/EventBeforeIcon";
import EventsIcon from "./Icons/EventsIcon";
import SharingModal from "./Modals/SharingModal";
import Tabs from "./Tabs/Tabs";
import AppointmentListContainer from "./AppointmentListContainer";
import { Button } from "./Button";
import MemberFilter from "./MemberFilter";
import { FilterByItemType } from "./SortDropdown";

export const useSelectedFilterTab = () => {
  const router = useRouter();
  const { query } = router;
  const selectedTab = query?.selectedTab ?? "all";
  const isMissingOutcomesActive = selectedTab === "missing-outcomes";
  const isMissingPackagesActive = selectedTab === "missing-packages";

  const isActive = (tab: string) => selectedTab === kebabCase(tab);

  return {
    isActive,
    isMissingOutcomesActive,
    isMissingPackagesActive,
  };
};

type AppointmentListType = {
  showOnlyUpcoming?: boolean;
  title?: string;
};

const AppointmentList: FC<AppointmentListType> = ({
  title,
  showOnlyUpcoming = false,
}) => {
  const router = useRouter();
  const query = router.query;

  const { hasFullAccess } = useAccessType();
  const isMobile = useIsMobile();

  const [selectedTab, setSelectedTab] = useState<"upcoming" | "past">(
    (query?.tab as "upcoming" | "past") ?? "upcoming"
  );
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);
  const [selectedHiddenOption, setSelectedHiddenOption] =
    useState<boolean>(true);
  const [selectedApptShareModal, setSelectedApptShareModal] =
    useState<FormattedPaginatedAppointment | null>(null);

  const { organization, oid } = useAuth();

  const { isActive, isMissingOutcomesActive, isMissingPackagesActive } =
    useSelectedFilterTab();

  const isSelectedTabPast = selectedTab === "past";

  const { options: memberFilterOptions } = useMemberFilterOptions();

  const { SortDropdown, filtersMap, selectedSort, setFiltersMap } =
    useListConfiguration<Appointment>(
      [],
      [
        {
          text: "All",
          value: "all",
          icon: <CalendarIcon />,
          transform: (items) => {
            return items;
          },
        },
        {
          text: "Appointments",
          value: "appointments",
          icon: <CalendarArrowIcon />,
          transform: (items) => {
            return items;
          },
        },
        {
          text: "Events",
          value: "events",
          icon: <EventsIcon />,
          transform: (items) => {
            return items;
          },
        },
      ],
      [
        {
          text: "Show hidden appts",
          transform: (items, _value) => items,
          key: "showHiddenAppts",
          defaultValue: false,
        },
        {
          text: "Without outcome",
          transform: (items, _value) => items,
          key: "withoutOutcome",
          defaultValue: isMissingOutcomesActive,
          hidden: true,
        },
        {
          text: "Without package",
          transform: (items, _value) => items,
          key: "withoutPackage",
          defaultValue: isMissingPackagesActive,
          hidden: true,
        },
        ...(memberFilterOptions && hasFullAccess
          ? ([
              {
                text: "Visibility",
                transform: (items: any[], _value: string) => items,
                options: memberFilterOptions,
                key: "visibility",
                defaultValue: "all",
                hidden: !isMobile,
              },
            ] as FilterByItemType[])
          : []),
      ],
      `appointmentsListConfiguration`,
      "Filter by"
    );

  const onlyEvents = selectedSort === "events";
  const memberId =
    filtersMap.visibility !== "all" ? filtersMap.visibility : undefined;
  const showHidden = filtersMap.showHiddenAppts === true;
  const withoutOutcome = filtersMap.withoutOutcome === true;
  const withoutPackage = filtersMap.withoutPackage === true;

  const {
    loading: isLoadingAppointments,
    loadMore,
    groupedAppointments,
  } = usePaginatedAppointments({
    oid,
    isPast: showOnlyUpcoming ? false : isSelectedTabPast,
    onlyEvents: onlyEvents,
    memberId: memberId ?? undefined,
    showHidden,
    withoutOutcome,
    withoutPackage,
  });

  const updateRoute = (key: string, value: string) => {
    router.replace({
      query: { ...router.query, [key]: value },
    });
  };

  const selectUpcomingTab = () => {
    setSelectedTab("upcoming");
    updateRoute("tab", "upcoming");

    if (isMissingOutcomesActive) {
      router.replace({
        query: {
          ...query,
          ["selectedTab"]: "all",
        },
      });
    }
  };
  const selectPastTab = () => {
    setSelectedTab("past");
    updateRoute("tab", "past");
  };

  const setFilters = (filters: Record<string, string>): void => {
    setFiltersMap({
      ...filtersMap,
      visibility: filters.memberId ?? "all",
    });
  };

  const renderMemberFilter = !isMobile && (
    <MemberFilter
      setFilters={setFilters}
      defaultValue={filtersMap?.visibility}
      listboxClassNames=" !h-[44px]"
    />
  );

  useEffect(() => {
    if (
      filtersMap.withoutOutcome !== isMissingOutcomesActive ||
      filtersMap.withoutPackage !== isMissingPackagesActive
    ) {
      setFiltersMap({
        ...filtersMap,
        withoutOutcome: isMissingOutcomesActive,
        withoutPackage: isMissingPackagesActive,
      });
    }
    if (selectedMemberId !== memberId) {
      setSelectedMemberId(memberId);
    }
    if (selectedHiddenOption !== showHidden) {
      setSelectedHiddenOption(showHidden);
    }
    if (onlyEvents) {
      router.replace({
        query: {
          ...query,
          ["selectedTab"]: "all",
        },
      });
    }
  }, [
    isMissingOutcomesActive,
    isMissingPackagesActive,
    memberId,
    showHidden,
    onlyEvents,
  ]);

  const renderTabLabel = (name: string, total: number = 0) => (
    <span className="text-base font-medium flex items-center">
      {name}
      {total > 0 && (
        <small
          className={classNames(
            "text-xs inline-block ml-2 rounded shrink-0 px-1 leading-4 pt-0.5",
            isActive(name) ? "bg-action-600" : "bg-grey-900"
          )}
        >
          {total}
        </small>
      )}
    </span>
  );

  const handleShare = (item: FormattedPaginatedAppointment) => {
    setSelectedApptShareModal(item);
    analytics.track("group-session_coach_share-modal-open");
  };

  const renderShareModal = () => {
    if (!selectedApptShareModal) return;

    const copyUrl = getArtifactLink(
      organization ?? {},
      selectedApptShareModal,
      "book-event"
    );

    return (
      !!selectedApptShareModal && (
        <SharingModal
          show={!!selectedApptShareModal}
          toggleShow={() => setSelectedApptShareModal(null)}
          artefactType="event"
          artefactLink={copyUrl}
          artefactItem={selectedApptShareModal}
        />
      )
    );
  };

  const renderButtonTab = ({
    label,
    onClick,
    selected,
  }: {
    label: string;
    selected: string;
    onClick: () => void;
  }) => {
    const tabBtnDefaultClass =
      "hover:border-action-950 focus:border-action-900 text-xl font-normal disabled:bg-transparent";

    return (
      <Button
        small
        overrideColors
        onClick={onClick}
        className={classNames(
          tabBtnDefaultClass,
          "mr-2 border-b-4 rounded-none pb-4 pt-6",
          selectedTab === selected
            ? "font-medium border-action-700 hover:border-action-700 focus:border-action-700"
            : "font-normal border-transparent"
        )}
        disabled={isLoadingAppointments}
      >
        {label}
      </Button>
    );
  };

  const renderNavBar = !showOnlyUpcoming ? (
    <div className="sticky top-0 bg-white z-10">
      <nav className="-mb-px flex justify-between items-center space-x-4 border-b border-grey-900">
        <div className="flex flex-1">
          {renderButtonTab({
            label: "Upcoming",
            selected: "upcoming",
            onClick: selectUpcomingTab,
          })}
          {renderButtonTab({
            label: "Past",
            selected: "past",
            onClick: selectPastTab,
          })}
        </div>
        {renderMemberFilter}
        {SortDropdown}
      </nav>
      <Tabs
        className="pt-6 pb-2"
        queryParamName="selectedTab"
        tabClassNames="bg-action-900"
        inactiveTabClassNames="!bg-grey-950"
      >
        <Tabs.Item name="All" header={renderTabLabel("All")} />
        {!onlyEvents && isSelectedTabPast && (
          <Tabs.Item
            name="Missing outcomes"
            header={renderTabLabel("Missing outcomes")}
          />
        )}
        {!onlyEvents && (
          <Tabs.Item
            name="Missing packages"
            header={renderTabLabel("Missing packages")}
          />
        )}
      </Tabs>
    </div>
  ) : (
    <div className="flex items-center">
      {title && (
        <h2 className="text-black-ink text-xl font-regular">{title}</h2>
      )}
      <div className="ml-auto">{SortDropdown}</div>
    </div>
  );

  const shouldDisplayOrganizer = filtersMap?.visibility === "all";

  const groupSortedByTab = (
    orderBy: "desc" | "asc"
  ): [string, GroupAppointment[]][] =>
    _.orderBy(
      Object.entries(groupedAppointments[selectedTab]),
      (item) => moment(item[0], "MMM YYYY"),
      [orderBy]
    );

  return (
    <>
      {renderNavBar}
      <AppointmentListContainer
        organizationId={oid!}
        appointments={
          showOnlyUpcoming
            ? groupedAppointments.upcoming
            : Object.fromEntries(
                groupSortedByTab(isSelectedTabPast ? "desc" : "asc")
              )
        }
        showContacts={true}
        group={showOnlyUpcoming ? "upcoming" : selectedTab}
        onShare={handleShare}
        isEventFilter={selectedSort === "events"}
        hideButton
        shouldDisplayOrganizer={shouldDisplayOrganizer}
        isLoading={isLoadingAppointments}
        loadMore={loadMore}
      />
      {renderShareModal()}
    </>
  );
};

export default withErrorBoundary(AppointmentList, {
  fallback: (
    <div className="flex w-100 p-12 justify-center">
      Failed to load appointments.
    </div>
  ),
});
